/* --------------------------------------------------------------------------
| FILE PROPOSITION VOTE EVENT PANEL
|-------------------------------------------------------------------------- */

import { handleOpenScreenVoteOnPanel, handleCloseScreenVoteOnPanel, handleOpenScreenVoteResultsOnPanel } from '../../functions/functions-propositions';
import { handleVoteConfirmation, handleVoteConfirmationScreenLoad, handleVoteCounts, vote } from '../../functions/functions-propositions-vote';

/* --------------------------------------------------------------------------
| EVENT -> ABERTURA DA VOTAÇÃO NO PAINEL...
|-------------------------------------------------------------------------- */
window.Echo.channel("vote-started")
    .listen(".vote-started", (e) => { // Fica ouvindo...
        handleOpenScreenVoteOnPanel(); // Abre a votação...
        window.location.reload();
    });

/* --------------------------------------------------------------------------
| EVENT ->Processando VOTAÇÃO NO PAINEL...
|-------------------------------------------------------------------------- */
window.Echo.channel("vote-processed")
    .listen(".vote-processed", (e) => { // Fica ouvindo...
        // handleOpenScreenVoteResultsOnPanel();
        window.location.reload();
    });

/* --------------------------------------------------------------------------
| EVENT -> ENCERRAMENTO DA VOTAÇÃO NO PAINEL...
|-------------------------------------------------------------------------- */
window.Echo.channel("vote-closed")
    .listen(".vote-closed", (e) => { // Fica ouvindo...
        handleCloseScreenVoteOnPanel(); // Fecha a votação...
        window.location.reload();
    });

/* --------------------------------------------------------------------------
| EVENT -> EVENTO DE CONFIRMAÇÃO DO VOTO DO VEREADOR NO PAINEL...
|-------------------------------------------------------------------------- */
window.Echo.channel('vote-done') // Seleciona o canal...
    .listen('.vote-done', (e) => { // Fica escutando...
        // handleVoteCounts(e.voteCounts);
        // handleVoteConfirmation(e.args.user_id, e.args.vote);
    });

/* --------------------------------------------------------------------------
| EVENT ->
|-------------------------------------------------------------------------- */
// addEventListener('load', handleVoteConfirmationScreenLoad);
