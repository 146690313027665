/*
|--------------------------------------------------------------------------
|----------------------| FUNCTIONS PROPOSITIONS |--------------------------
|--------------------------------------------------------------------------
*/

import { articlePersonComponent, articleTableComponent, badgeComponent, buttonDownloadComponent, titleComponent } from './functions-components'
import { handleCloseScreenPagesOnPanel, handleFetch, handleOpenScreenPagesOnPanel, handleSelectScreensOnPanel } from './functions-helpers';

/* --------------------------------------------------------------------------
| FUNCTION -> SELECIONA A PROPOSIÇÃO DA ORDEM...
|-------------------------------------------------------------------------- */
export const handleGetProposition = (event) => {
	const element = event.currentTarget; // Cria uma variavel com o valor do elemento vindo pelo parâmetro...
	return element;
}

/* --------------------------------------------------------------------------
| FUNCTION -> FAZ A REQUISIÇÃO PARA PEGAR TODAS AS INFORMAÇÕES DA PROPOSIÇÃO...
|-------------------------------------------------------------------------- */
export const handleGetPropositionDetail = async (elem) => {
	let data = await handleFetch(elem.dataset.proposition);
	handleRenderTarget(data);
}

/* --------------------------------------------------------------------------
| FUNCTION -> SELECIONA O ELEMENTO DESTINO ONDE A PROPOSIÇÃO VAI SER RENDERIZADA...
|-------------------------------------------------------------------------- */
export const handleRenderTarget = (element) => {
	const target = document.querySelector('.proposition__details__wrapper');
	target.innerHTML = handleRenderProposition(element);

	let procedureDetailsButtons = document.querySelectorAll('.button-tramit-details');
    procedureDetailsButtons.forEach((element)=>{
        element.addEventListener('click', (event) => {
            let current = event.currentTarget;
            let father = current.closest('article.article-table');
            let details = father.querySelector('.article-table__infos');

            if (current.getAttribute('data-id') === details.id) {
                details.classList.toggle('article-table__infos--show');
            }
        })
    });
}

/* --------------------------------------------------------------------------
| FUNCTION -> RENDERIZA A PROPOSIÇÃO DETALHADA...
|-------------------------------------------------------------------------- */
export const handleRenderProposition = (proposition) => {
	const element = `
        <article class="card-proposition-detail">
            <div class="card-proposition-detail__header">
				<h6> ${proposition.number}/${proposition.year} - ${proposition.title} </h6>
                ${handleRenderPropositionButtonDownloadFile(proposition)}
                <hr>
            </div>

            <div class="card-proposition-detail__body">
				<div class="card-proposition-detail__body__content">
					${badgeComponent('ID / Hash', `${proposition.id} / ${proposition.hash}`)}
					${badgeComponent('Espécie', proposition.message_model_relationship ? proposition.message_model_relationship.name : '-')}
					${badgeComponent('Localização', proposition.geo_referencing)}
				</div>

                <div class="card-proposition-detail__body__content">
                    <p class="card-proposition-detail__caption"> Descrição </p>
                    <div> ${proposition.description} </div>
                </div>

				<div class="card-proposition-detail__body__content">
                    ${handleRenderPropositionAuthors(proposition)}
                </div>

				<div class="card-proposition-detail__body__content">
                    ${handleRenderPropositionExternalProposer(proposition)}
                </div>

                <div class="card-proposition-detail__body__content">
                    ${handleRenderPropositionInternalProposer(proposition)}
                </div>

                <div class="card-proposition-detail__body__content">
					${titleComponent('Histórico de tramitação')}

					<article class="article-table">
						<div class="article-table__head">
							<span class="article-table__item"> <strong> Tramitação </strong> </span>
							<span class="article-table__item"> <strong> Data da ação </strong> </span>
							<span class="article-table__item"> - </span>
						</div>
					</article>

					${handleRenderPropositionTramit(proposition)}
                </div>
            </div>

			<div class="card-proposition-detail__footer"></div>
        </article>
    `
	return element;
}

/* --------------------------------------------------------------------------
| FUNCTION -> RENDERIZA O BOTÃO DE DOWNLOAD DO ARQUIVO DA PROPOSIÇÃO...
|-------------------------------------------------------------------------- */
export const handleRenderPropositionButtonDownloadFile = (proposition) => {
	let propositionDownload = `${buttonDownloadComponent(proposition.document_relationship !== null && proposition.document_relationship.path)}`;

	return proposition.document_relationship !== null ? propositionDownload : '';
}

/* --------------------------------------------------------------------------
| FUNCTION -> RENDERIZA O PROPONENTE EXTERNO DA PROPOSIÇÃO...
|-------------------------------------------------------------------------- */
export const handleRenderPropositionExternalProposer = (proposition) => {
	let externalProposer = `
        ${titleComponent('Proponentes externos')}
		${articlePersonComponent(null, null, proposition.external_proposer, null)}
    `;

	return proposition.external_proposer !== null ? externalProposer : '';
}

/* --------------------------------------------------------------------------
| FUNCTION -> RENDERIZA OS PROPONENTES INTERNOS DA PROPOSIÇÃO...
|-------------------------------------------------------------------------- */
export const handleRenderPropositionInternalProposer = (proposition) => {
	let internalProposers = `
        ${titleComponent('Assinantes')}

        ${proposition.internal_proposers_relationship
			.map((element) => {
				return articlePersonComponent(
					element.user_relationship_photo ? element.user_relationship_photo.path : '',
					element.name,
					element.name,
					element.user_information_relationship.information_relationship_political_party
						? element.user_information_relationship.information_relationship_political_party.acronym : '-'
				)
			})
			.join('')}
    `;

	return proposition.internal_proposers_relationship.length !== 0 ? internalProposers : '';
}

/* --------------------------------------------------------------------------
| FUNCTION QUE RENDERIZA OS AUTORES DA PROPOSIÇÃO...
|-------------------------------------------------------------------------- */
export const handleRenderPropositionAuthors = (proposition) => {
    let internalProposers = `
        ${titleComponent('Autores')}

        ${proposition.author_relationship_proposition.map(element => {
            return articlePersonComponent(
                element.user_relationship_photo ? element.user_relationship_photo.path : '',
                element.name,
                element.name,
                element.user_information_relationship.information_relationship_political_party
                    ? element.user_information_relationship.information_relationship_political_party.acronym : '-'
            );
        }).join('')}
    `;

    return (proposition.author_relationship_proposition.length !== 0) ? internalProposers : '';
}


/* --------------------------------------------------------------------------
| FUNCTION QUE RENDERIZA AS TRAMITAÇÕES DA PROPOSIÇÃO...
|-------------------------------------------------------------------------- */
export const handleRenderPropositionTramit = (proposition) => {
	let tramitsProposition = `
        ${proposition.procedure_relationship
			.map((element) => {
				return articleTableComponent(
					element.id,
					element.created_at,
					element.name,
					element.procedure_structure,
					element.slug,
				)
			})
			.join('')}
    `;

    return (proposition.procedure_relationship.length !== 0) ? tramitsProposition : '';
}

/* --------------------------------------------------------------------------
| FUNCTION -> SELECIONA AS VIEWS DO PAINEL...
|-------------------------------------------------------------------------- */
// export const handleSelectScreensOnPanel = () => {
// 	let sectionHome = document.querySelector('#section__panel__home');
// 	let sectionPropositionRead = document.querySelector('#section__panel__proposition__read');
// 	let sectionPropositionVote = document.querySelector('#section__panel__proposition__vote');
// 	let sectionPropositionVoteResult = document.querySelector('#section__panel__proposition__vote__result');

// 	return { sectionHome, sectionPropositionRead, sectionPropositionVote, sectionPropositionVoteResult }
// }

/* --------------------------------------------------------------------------
| FUNCTION -> ABRE A LEITURA NO PAINEL...
|-------------------------------------------------------------------------- */
export const handleOpenScreenReadOnPanel = () => {
	let sectionPropositionRead = document.querySelector('#section__panel__proposition__read');
	handleOpenScreenPagesOnPanel(sectionPropositionRead);

	// let { sectionHome, sectionPropositionRead, sectionPropositionVote } = handleSelectScreensOnPanel();
	// if (sectionPropositionRead.classList.contains('none-element')) {
	// 	sectionHome.classList.add('none-element');
	// 	sectionPropositionVote.classList.add('none-element');
	// 	sectionPropositionRead.classList.remove('none-element');
	// }
}

/* --------------------------------------------------------------------------
| FUNCTION -> FECHA A LEITURA NO PAINEL...
|-------------------------------------------------------------------------- */
export const handleCloseScreenReadOnPanel = () => {
	let sectionPropositionRead = document.querySelector('#section__panel__proposition__read');
	handleCloseScreenPagesOnPanel(sectionPropositionRead);

	// let { sectionHome, sectionPropositionRead, sectionPropositionVote, sectionPropositionVoteResult } = handleSelectScreensOnPanel();

	// if (!sectionPropositionRead.classList.contains('none-element')) {
	// 	sectionPropositionRead.classList.add('none-element');
	// 	sectionPropositionVote.classList.add('none-element');
	// 	sectionPropositionVoteResult.classList.add('none-element');
	// 	sectionHome.classList.remove('none-element');
	// }
}

/* --------------------------------------------------------------------------
| FUNCTION -> ABRE A VOTAÇÃO NO PAINEL...
|-------------------------------------------------------------------------- */
export const handleOpenScreenVoteOnPanel = () => {
	let sectionPropositionVote = document.querySelector('#section__panel__proposition__vote');
	handleOpenScreenPagesOnPanel(sectionPropositionVote);

	// let { sectionHome, sectionPropositionRead, sectionPropositionVote, sectionPropositionVoteResult } = handleSelectScreensOnPanel();
	// if (sectionPropositionVote.classList.contains('none-element')) {
	// 	sectionHome.classList.add('none-element');
	// 	sectionPropositionRead.classList.add('none-element');
	// 	sectionPropositionVoteResult.classList.add('none-element');
	// 	sectionPropositionVote.classList.remove('none-element');
	// }
}

/* --------------------------------------------------------------------------
| FUNCTION QUE MOSTRA O RESULTADO NO PAINEL...
|-------------------------------------------------------------------------- */
export const handleOpenScreenVoteResultsOnPanel = () => {
	let sectionPropositionVoteResult = document.querySelector('#section__panel__proposition__vote__result');
	handleOpenScreenPagesOnPanel(sectionPropositionVoteResult);

	// let { sectionHome, sectionPropositionRead, sectionPropositionVote, sectionPropositionVoteResult } = handleSelectScreensOnPanel();

	// if (sectionPropositionVoteResult.classList.contains('none-element')) {
	// 	sectionHome.classList.add('none-element');
	// 	sectionPropositionRead.classList.add('none-element');
	// 	sectionPropositionVote.classList.add('none-element');
	// 	sectionPropositionVoteResult.classList.remove('none-element');
	// }
}

/* --------------------------------------------------------------------------
| FUNCTION -> FECHA A VOTAÇÃO NO PAINEL...
|-------------------------------------------------------------------------- */
export const handleCloseScreenVoteOnPanel = () => {
	let sectionPropositionVoteResult = document.querySelector('#section__panel__proposition__vote__result');
	handleCloseScreenPagesOnPanel(sectionPropositionVoteResult);

	// let { sectionHome, sectionPropositionRead, sectionPropositionVote, sectionPropositionVoteResult } = handleSelectScreensOnPanel();

	// if (!sectionPropositionVoteResult.classList.contains('none-element') || !sectionPropositionVote.classList.contains('none-element')) {
	// 	sectionPropositionRead.classList.add('none-element');
	// 	sectionPropositionVote.classList.add('none-element');
	// 	sectionPropositionVoteResult.classList.add('none-element');
	// 	sectionHome.classList.remove('none-element');
	// }
}
